@use '@/styles/mixins';

.container {
  overflow-x: scroll;
  max-height: 100%;

  .table {
    background-color: var(--bg-primary);
    color: var(--text-tertiary-600);
    border-collapse: separate;
    border-spacing: 0;
    table-layout: auto;
    border: 1px solid var(--border-secondary);
    border-radius: 12px;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;

    thead {
      tr {
        // header styles
        font: var(--sm-medium);
        color: var(--text-tertiary-600);
        text-align: left;

        th {
          padding: 12px 24px;
          border-bottom: 1px solid var(--border-secondary);

          .cell {
            display: flex;
            gap: 3px;
            align-items: center;
            font: var(--xs-medium);
            color: var(--text-tertiary-600);

            .sort {
              cursor: pointer;
            }

            svg {
              min-width: 16px;
              min-height: 16px;
            }
          }
        }
      }
    }
    .loading {
      .container {
        display: flex;
        min-height: calc(100vh - 350px);
        align-items: center;
        justify-content: center;
      }
    }

    tbody {
      font: var(--xs-medium);
      color: var(--text-tertiary-600);

      tr {
        &.animate {
          @include mixins.fade-in;
        }

        &:nth-of-type(odd) {
          background-color: var(--utility-gray-50);
        }

        &:not(:last-child) td {
          border-bottom: 1px solid var(--border-secondary);
        }

        &:last-child td {
          &:first-of-type {
            border-radius: 0 0 0 12px;
          }

          &:last-of-type {
            border-radius: 0 0 12px 0;
          }
        }

        &.clickable {
          cursor: pointer;
          &:hover {
            background-color: var(--blue-1);

            td {
              border-bottom: 1px solid var(--blue);
              border-top: 1px solid var(--blue);

              &:first-of-type {
                border-left: 1px solid var(--blue);
                border-radius: 6px 0 0 6px;
              }
              &:last-of-type {
                border-right: 1px solid var(--blue);
                border-radius: 0 6px 6px 0;
              }
            }

            &:active {
              opacity: 0.7;
            }
          }
        }

        td {
          border: 1px solid transparent;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 16px 24px;
          max-width: 300px;
          font: var(--sm-medium);
        }
      }
    }

    tfoot {
    }
  }
}
