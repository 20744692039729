@keyframes spinner-animation {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  transform-origin: center;
  animation: spinner-animation 0.75s infinite linear;
}
