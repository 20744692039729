.header {
  margin: 4px 0 0;
  color: var(--text-primary-900, #101828);
  font: var(--lg-semibold);
}

.header span {
  display: block;
  color: var(--text-tertiary-600, #475467);
  font: var(--sm-regular);
}
