.container {
  height: calc(100vh - 350px);
  display: flex;
  align-items: center;
  justify-content: center;
  .error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 380px;
    gap: 6px;
    font: var(--sm-medium);
    background-color: transparent;

    svg {
      height: 48px;
      width: 48px;
      margin: 12px;
    }

    .header {
      color: var(--text-primary-900);
      font: var(--md-semibold);
    }

    .text {
      text-align: center;
      color: var(--text-tertiary-600);
      font: var(--sm-regular);
    }
    .buttons {
      margin-top: 18px;
      display: flex;
      gap: 12px;
      .supportButton {
        background-color: transparent;
        color: var(--text-tertiary-600);
        border-color: var(--gray-3);
      }
    }
  }
}
