.sectionTitle {
  color: var(--text-primary-900, #101828);
  font: var(--lg-semibold);
  margin-bottom: 4px;
}

.sectionSubTitle {
  color: var(--text-tertiary-600, #475467);
  font: var(--xs-regular);
}

.text {
  color: var(--text-tertiary-600, #101828);
  font: var(--md-regular);
}

.text ul {
  padding-inline-start: 8px;
  margin-inline-start: 8px;
}
.text h4 {
  padding: 32px 0 var(--spacing-lg, 12px) 0;
  font: var(--md-medium);
}
