@use '@/styles/mixins';

.modal {
  @include mixins.scrollable;
  border-radius: var(--radius-xl, 12px);
  background: var(--Colors-Background-bg-primary, #fff);

  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  display: flex;

  min-height: 200px;
  max-height: 80vh;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    color: #101828;
    text-align: center;
    font: var(--lg-semibold);
    padding: 24px;
  }

  .content {
    padding: 0 24px 20px 24px;
    flex: 1;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    max-width: 450px;
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    align-self: stretch;
    border-top: 1px solid #eaecf0;
  }

  .close {
    padding: 10px var(--spacing-xl, 16px);
    text-align: center;
    border-radius: var(--radius-md, 8px);
    border: 1px solid #d0d5dd;
    width: 100%;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(#344054);
    cursor: pointer;
    font: var(--md-semibold);
  }
}
