.section {
  display: flex;
  flex-wrap: nowrap;
  gap: var(--spacing-3xl);
  align-items: stretch;
  justify-content: flex-start;
}

.threeCollCardsSection > div {
  width: 33.3333%;
}

.twoCollCardsSection > div,
.twoCollCardsSection > table {
  width: 50%;
}
