.chartTitle {
  font: var(--sm-medium);
}

.doughnut {
  max-width: 50%;
}

.doughnutGrid {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-xl, 16px);
  column-gap: 12px;
}

.doughnutGrid ul {
  display: block;
  width: max-content;
}

.doughnutGrid li {
  display: block;
  overflow: hidden;
  color: var(--text-tertiary-600, #475467);
  font: var(--sm-regular);
  white-space: nowrap;
  text-overflow: ellipsis;
  column-gap: var(--spacing-xl, 16px);
  cursor: pointer;
}

.doughnutGrid li:hover {
  text-decoration: underline;
}

.legendColor {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 4px 0 0;
  border-radius: 50%;
}
