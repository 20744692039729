.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 240px;
  view-timeline: --header-vtl;
  overflow: hidden;
  background: linear-gradient(45deg, #a6c0fe 0%, #ffeaf6 100%);
}

@keyframes adjust-image {
  0% {
    translate: 0% 0% 0;
  }

  100% {
    translate: 0% 90% 0;
  }
}

.header svg:nth-of-type(1) {
  position: relative;
  animation: adjust-image linear both;
  animation-timeline: --header-vtl;
  animation-range: exit;
}

.header svg:nth-of-type(2) {
  position: absolute;
}
