table tfoot tr td .pagination {
  border-top: 1px solid var(--border-secondary);
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  .button {
    user-select: none;
    padding: 8px 12px;
    border-radius: var(--radius-md, 8px);
    border: 1px solid var(--border-primary);
    background: var(--button-primary-fg);
    display: flex;
    align-items: center;
    gap: 6px;
    font: var(--sm-semibold);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;

    &:hover:not(.disabled) {
      border-color: var(--blue);
      background-color: var(--blue-1);

      &:active {
        opacity: 0.7;
      }
    }

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  .key {
    font: var(--sm-medium);
    color: var(--text-secondary-700);
    margin-left: auto;
  }
}
