.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.title {
  color: var(--text-primary-900, #101828);
  font: var(--xl-semibold);
}

.subtitle {
  color: var(--text-tertiary-600, #475467);
  font: var(--md-regular);
}
