.action {
  .title {
    color: var(--text-primary-900);
    font: var(--sm-medium);
  }
  .description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--text-tertiary-600);
    font: var(--sm-regular);
  }
}
