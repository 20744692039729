.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl, 16px);
  align-items: center;
  color: var(--text-tertiary-600, #475467);
  font: var(--sm-regular);
  background: var(--bg-primary, #fff);
  border: 1px solid var(--border-secondary, #eaecf0);
  border-radius: var(--radius-xl, 12px);
  box-shadow: var(--shadow-xs);
  cursor: default;
  justify-content: space-between;
}

.innerWrapper {
  width: 100%;
  padding: var(--spacing-3xl, 16px);
}

.action {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid var(--border-secondary, #eaecf0);
}

.headerWrapper {
  display: flex;
  gap: var(--spacing-lg, 12px);
  margin-bottom: var(--spacing-lg, 12px);
}

.title {
  color: var(--text-primary-900, #101828);
  font: var(--md-semibold);
}

.subTitle {
  color: var(--text-tertiary-600, #475467);
  font: var(--sm-medium);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #f1e5ff;
  border-radius: 50%;
}

.icon svg {
  width: 24px;
  height: 24px;
}

.numericCardTitle {
  height: 48px;
  font: var(--sm-medium);
}

.numericCardContentValue {
  color: var(--text-primary-900, #101828);
  font-weight: 600;
  font-size: 30px;
}

.cardLink,
.cardLink:visited {
  color: var(--buttons-primary, #7f56d9);
  font: var(--sm-semibold);
  text-decoration: none;
}
