.container {
  background-color: var(--utility-gray-50);
  flex: 1;
}

.cardContent {
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.spinner {
  align-self: end;
  position: absolute;
}

.sectionTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fakeCardLink {
  cursor: pointer;
  color: var(--buttons-primary, #7f56d9);
  font: var(--sm-semibold);
  text-decoration: none;
}
