.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #ededed;
  background-size: cover;
  border-radius: 50%;
}

.company {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
}

.company a {
  color: var(--text-tertiary-600);
  text-decoration: none;
}

.company a:hover {
  text-decoration: underline;
}
