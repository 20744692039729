.container {
  display: flex;
  gap: 12px;
  align-items: center;

  .progress {
    width: 100px;
    height: 20px;
    background-color: #eaecf0;
    height: 8px;
    border-radius: 24px;

    .bar {
      transition: width 0.24s ease-in;
      height: 100%;
      background-color: #7f56d9;
      border-radius: 24px;
    }
  }
}
