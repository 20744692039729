.target {
  position: relative;
  display: flex;
  cursor: pointer;
}
.tooltip {
  position: absolute;
  background-color: #0c111d;
  color: var(--border-primary);
  font-size: 12px;
  padding: 12px;
  border-radius: 6px;
  font: var(--xs-medium);
  max-width: 320px;
  z-index: 9999;
  list-style-position: inside;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  .header {
    color: var(--bg-primary);
    font: var(--xs-semibold);
  }

  border-radius: 12px;
  box-shadow:
    0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  // opacity: 0;
  // transition: opacity 0.12s ease-in;

  // &.visible {
  //   opacity: 1;
  // }

  &.above {
    min-width: 60px;
    transform: translate(calc(-50% + 8px), calc(-100% - 15px));
  }

  &.below {
    min-width: 60px;
    transform: translate(calc(-50% + 8px), calc(16px + 15px));
  }

  &.left {
    min-height: 60px;
    transform: translate(calc(-100% - 15px), calc(-50% + 3px));
  }

  &.right {
    min-height: 60px;
    transform: translate(16px + 15px, calc(-50% + 3px));
  }

  .arrow {
    position: absolute;
    width: 0;
    height: 0;

    &.above {
      left: calc(50% - 31px);
      bottom: -8px;
      border-top: 12px solid #0c111d;
      border-right: 31px solid transparent;
      border-left: 31px solid transparent;
    }

    &.below {
      top: -8px;
      left: calc(50% - 31px);
      border-right: 31px solid transparent;
      border-bottom: 12px solid #0c111d;
      border-left: 31px solid transparent;
    }

    &.left {
      top: calc(50%);
      right: -18px;
      border-right: 15.5px solid transparent;
      border-bottom: 12px solid #0c111d;
      border-left: 15.5px solid transparent;
      transform: rotate(90deg);
    }

    &.right {
      top: calc(50%);
      left: -18px;
      border-right: 15.5px solid transparent;
      border-bottom: 12px solid #0c111d;
      border-left: 15.5px solid transparent;
      transform: rotate(-90deg);
    }
  }
}
@keyframes fade-in {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  60% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}
